import 'firebase/firestore';

import firebase from 'firebase/app';
import { useState } from "react";

export default function useDatabase() {

    const [schedules, setSchedules] = useState([])
    const [logs, setLogs] = useState([])
    const [errors, setErrors] = useState([])

    const startScheduleListener = () => firebase.auth().onAuthStateChanged(user => {
        if (user){
            firebase.firestore().collection('schedules').where('owner', '==', user.uid).onSnapshot(data => {
                let temp = []
                if (!data.empty){
                    data.forEach(el => {
                        temp.push({...el.data(), uid: el.id})
                    })
                    setSchedules(temp)
                }else{
                    setSchedules(temp)
                }
            })
        }else{
            window.location.replace('/')
        }
    })

    const startErrorListener = () => firebase.auth().onAuthStateChanged(user => {
        if (user){
            firebase.firestore().collection('errors').onSnapshot(data => {
                let temp = []
                if (!data.empty){
                    data.forEach(el => {
                        temp.push({...el.data(), uid: el.id})
                    })
                    setErrors(temp.sort((a, b)=> new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()))
                }else{
                    setErrors(temp)
                }
            })
        }else{
            window.location.replace('/')
        }
    })

    const startLogListener = () => firebase.auth().onAuthStateChanged(user => {
        if (user){
            firebase.firestore().collection('logs').where('owner', '==', user.uid).limit(100).onSnapshot(data => {
                let temp = []
                if (!data.empty){
                    data.forEach(el => {
                        temp.push({...el.data(), uid: el.id})
                    })
                    setLogs(temp.sort((a, b)=> new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()))
                }else{
                    setLogs([])
                }
            })
        }else{
            window.location.replace('/')
        }
    })

    const getUser = (u) => firebase.auth().onAuthStateChanged(user => {
        if (user){
            firebase.firestore().collection('users').doc(user.uid).onSnapshot(data => {
                if (data.exists){
                    u(data.data(), user)
                }
            })
        }else{
            window.location.replace('/')
        }
    })

    return {
        schedules,
        startScheduleListener,
        logs,
        startLogListener,
        getUser,
        startErrorListener,
        errors
    }
}