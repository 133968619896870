import React from 'react';

import Appbar from '../components/appBar';
import Footer from '../components/footer';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
  },
}));

export default function Main({children}) {
    const classes = useStyles()

    return(
        <div className={classes.container}>
            <div style={{margin: 5}}>
            <Appbar/>
                <div style={{minHeight: '100vh'}}>
                    {children}
                </div>
            <Footer/>
            </div>
        </div>
    )
}