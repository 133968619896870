import React, { useEffect } from 'react';

import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import Copyright from '../components/copyright';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { ToastContainer } from 'react-toastify';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import useLogin from '../hooks/useLogin';
import useUtilities from '../hooks/useUtilities';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
    const classes = useStyles();

    const {getCleverCloudAccessToken, logIn, forgotPassword, 
      setForgotPassword, resetPassword, updateUser, user, loading} = useLogin()

    const util = useUtilities()

    useEffect(() => {
      if (util.useQuery().get('oauth_verifier')){
          localStorage.setItem("oauth_verifier", util.useQuery().get('oauth_verifier'))
          localStorage.setItem("oauth_token", util.useQuery().get('oauth_token'))
          getCleverCloudAccessToken()
      }
    }, [])

    return (
        <>
        <ToastContainer/>
        <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={classes.paper}>
            <Avatar className={classes.avatar}>
                <AccessAlarmsIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>
            <form className={classes.form} noValidate>
                <TextField
                variant="outlined"
                margin="normal"
                required
                value={user.email}
                onChange={updateUser}
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                />
                {!forgotPassword && <TextField
                variant="outlined"
                margin="normal"
                required
                value={user.password}
                onChange={updateUser}
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                />}
                <Button
                type="submit"
                fullWidth
                onClick={forgotPassword ? resetPassword : logIn}
                variant="contained"
                color="primary"
                className={classes.submit}
                >
                  {loading ? <CircularProgress color='inherit'/> :forgotPassword ? "Send Reset Email" : "Sign In"}             
                </Button>
                <Grid container>
                <Grid item xs>
                    <Link href="#" onClick={() => setForgotPassword(!forgotPassword)} variant="body2">
                        {forgotPassword ? "Rather sign in ?" : "Forgot password?"}
                    </Link>
                </Grid>
                </Grid>
                <Box mt={5}>
                <Copyright />
                </Box>
            </form>
            </div>
        </Grid>
        </Grid>
    </>
    );
}