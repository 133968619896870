import Base64 from 'crypto-js/enc-base64';
import { ScheduleModel } from '../models/object.models';
import axios from 'axios';
import firebase from 'firebase/app';
import hmacSHA1 from 'crypto-js/hmac-sha1';
import { toast } from 'react-toastify';

const OAuth = require('oauth-1.0a');

const token = {
    key: process.env.REACT_APP_CC_CONSUMER_KEY,
    secret: process.env.REACT_APP_CC_CONSUMER_SECRET,
}

export default function useCleverCloud() {

    const getKeys = (keys) => firebase.auth().onAuthStateChanged(user => {
        if (user){
            firebase.firestore().collection('users').doc(user.uid).get().then(data => {
                if (data.exists){
                    const key = data.data().token
                    const secret = data.data().secret
                    keys({key, secret})
                }
            })
        }
    })

    const requestHeaders = (url, secret, method) => {
        const request_data = {
            url: url,
            method: method
        }
        const oAuth = OAuth({
            consumer: {
                ...token,
            },
            signature_method: 'HMAC-SHA1',
            hash_function(base_string, key) {
                return Base64.stringify(hmacSHA1(base_string, key))
            },
        })
  
        return {
            "Accept": 'application/json',
            "Accept-Language": "en-US",
            "Content-Type": "application/json",
            'Connection': 'keep-alive', 
            ...oAuth.toHeader(oAuth.authorize(request_data, secret))
        }
    }

    const getNonce = () => {
        const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~'
        const result = [];
        window.crypto.getRandomValues(new Uint8Array(32)).forEach(c =>
            result.push(charset[c % charset.length]));
        return result.join('');
    }

    const getCleverCloudRequestToken = async (callback = (_res = {success: false, token: ''}, rej) => {}) => {
    
        function getOauthParams ( consumerKey, consumerSecret, tokenSecret = '' ) {
            return {
            oauth_consumer_key: consumerKey,
            oauth_signature_method: 'PLAINTEXT',
            oauth_signature: consumerSecret + '&' + tokenSecret,
            oauth_timestamp: new Date().getTime(),
            oauth_nonce: getNonce(),
            oauth_callback: process.env.REACT_APP_CC_CALLBACK_URL
            };
        }

        const searchParams = Object.keys(getOauthParams(token.key, token.secret)).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(getOauthParams(token.key, token.secret)[key]);
        }).join('&');
        
        axios({
            url: "https://api.clever-cloud.com:443/v2/oauth/request_token",
            method: "POST",
            headers: {
                "Accept-Language": "en-US,en;q=0.9",
                Connection: "keep-alive",
                Accept: "application/x-www-form-urlencoded",
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: searchParams
        }).then(({data}) => {
            const values = data.split('=')
            const _token = values[1].split('&')[0]
            const _secret = values[2].split('&')[0]
                localStorage.setItem('temp_secret', _secret)
            callback({success: true, token: _token}, false)
        }).catch(err => {
            console.log('access token', err);
            callback({success: false, token: ''}, err.message)
        })
        
    };

    const getCleverCloudAccessToken = (callback = (_res = {success: false, token: ''}, rej) => {}) => {
            
        function getOauthParams ( consumerKey, consumerSecret, secret ) {
            return {
                oauth_consumer_key: consumerKey,
                oauth_token: localStorage.getItem("oauth_token"),
                oauth_signature_method: 'PLAINTEXT',
                oauth_signature: consumerSecret + '&' + secret,
                oauth_timestamp: new Date().getTime(),
                oauth_nonce: getNonce(),
                oauth_version: 'v2',
                oauth_verifier: localStorage.getItem("oauth_verifier"),
                oauth_callback: process.env.REACT_APP_CC_CALLBACK_URL
            };
        }
        console.log(localStorage.getItem('temp_secret'));
        const searchParams = Object.keys(getOauthParams(token.key, token.secret, localStorage.getItem('temp_secret'))).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(getOauthParams(token.key, token.secret, localStorage.getItem('temp_secret'))[key]);
        }).join('&');

        axios({
            url: "https://api.clever-cloud.com:443/v2/oauth/access_token",
            method: "POST",
            headers: {
                "Accept-Language": "en-US,en;q=0.9",
                Connection: "keep-alive",
                Accept: "application/x-www-form-urlencoded",
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: searchParams
        }).then(({data}) => {
            localStorage.removeItem('temp_secret')
            localStorage.removeItem("oauth_verifier")
            localStorage.removeItem("oauth_token")

            const _token = data.split('=')[1].split('&')[0]
            const _secret = data.split('=')[2]
            
            callback({success: true, token: {token: _token, secret: _secret}}, false)
        }).catch(err => {
            console.log(err);
            localStorage.removeItem('temp_secret')
            localStorage.removeItem("oauth_verifier")
            localStorage.removeItem("oauth_token")

            callback({success: false, token: ''}, err.message)
        })
    };

    const getCleverCloudUser = async (callback = (_res = {success: false, user: ''}, rej) => {}) => {
        const url = "https://api.clever-cloud.com/v2/self"
        const method = "GET"
        
        getKeys(async ({key, secret}) => {
            try {
                const data = await fetch(url, {
                    method: method,
                    headers: requestHeaders(url, {
                        key: key,
                        secret: secret
                    }, method)
                });
                data.json().then(information => {
                    if (information.type === "error") {
                        callback({ success: false, user: false }, information.message);
                    } else {
                        callback({ success: true, user: { cleverCloudID: information.id, cleverCloudName: information.name } }, false);
                    }
                });
            } catch (err) {
                callback({ success: false, user: false }, err.message);
            }
        })  
    }

    const getCleverCloudOrganisation = async (CCUserId, callback = (_res = {success: false, organisations: ''}, rej) => {}) => {
      const url = "https://api.clever-cloud.com/v2/organisations?user=" + CCUserId
        const method = "GET"
        
        getKeys(async ({key, secret}) => {
            try {
                const data = await fetch(url, {
                    method: method,
                    headers: requestHeaders(url, {
                        key: key,
                        secret: secret
                    }, method)
                });
                data.json().then(information => {
                    if (information.type === "error") {
                        callback({ success: false, organisations: false }, information.message);
                    } else {
                        let organisations = [];
                        information.forEach(element => {
                            organisations.push({
                                organisationName: element.name,
                                organisationId: element.id
                            });
                        });
                        callback({ success: true, organisations: organisations }, false);
                    }
                });
            } catch (err) {
                callback({ success: false, organisations: false }, err.message);
            }
        })
    }
    
    const getCleverCloudOrganisationApplications = async (orgs, callback = (_res = {success: false, applications: ''}, rej) => {}) => {


        let applications = [];
        let promises = []

        orgs.forEach( organisation => {
            promises.push(new Promise((res, rej) => {
                const url = "https://api.clever-cloud.com/v2/organisations/" + organisation.organisationId + "/applications"
                const method = "GET"

                getKeys(async ({key, secret}) => {
                    try {
                        const data = await fetch(url, {
                            method: method,
                            headers: requestHeaders(url, {
                                key: key,
                                secret: secret
                            }, method)
                        });
                        data.json().then(information => {
                            if (information.type === "error") {
                                callback({ success: false, applications: false }, information.message);
                                res(true)
                            } else {
                                information.forEach((app) => {
                                    applications.push({
                                        organisationId: organisation.organisationId,
                                        applicationName: app.name,
                                        applicationId: app.id
                                    });
                                });
                                res(true)
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        callback({ success: false, applications: false }, err.message);
                        res(true)
                    }
                })
            }))
        })

        await Promise.all(promises)
        return callback({ success: true, applications: applications }, false);
    }

    const getCleverCloudOrganisationApplicationInstances = async (orgId, appId, callback = (_res = {success: false, applications: ''}, rej) => {}) => {

                const url = "https://api.clever-cloud.com/v2/organisations/" + orgId + "/applications/" + appId + "/instances"
                const method = "GET"

                getKeys(async ({key, secret}) => {
                    try {
                        const data = await fetch(url, {
                            method: method,
                            headers: requestHeaders(url, {
                                key: key,
                                secret: secret
                            }, method)
                        });
                        data.json().then(information => {
                            if (information.type === "error") {
                                callback({ success: false, applications: false }, information.message);
                            } else {
                                return callback({ success: true, application: information }, false);
                            }
                        });
                    } catch (err) {
                        callback({ success: false, applications: false }, err.message);
                    }
        })

    }


    const setOrganisationApplicationConfig = async (schedules = [ScheduleModel], callback) => {
        let promises = []

        getKeys(async ({key, secret}) => {
            schedules.forEach( schedule => {
                promises.push(new Promise(async (res, rej) => {
                    if (schedule.stopApplication){
                        stopOrganisationApplication(schedule, result => {
                            if (result){
                                res(result)
                            }else{
                                rej(result)
                            }
                        })
                    }else{
                        const url = "https://api.clever-cloud.com/v2/organisations/" + schedule.organisationId + "/applications/" + schedule.applicationId
                        const method = "PUT"
                        const data = {
                            maxFlavor: schedule.maxFlavour,
                            maxInstances: schedule.maxInstances,
                            minFlavor: schedule.minFlavour,
                            minInstances: schedule.minInstances
                        }

                        const reponse = await fetch(url, {
                            method: method,
                            headers: requestHeaders(url, {
                                    key: key,
                                    secret: secret
                                }, method),
                                body: JSON.stringify({
                                ...data
                            })
                        })

                        const information = reponse.json();

                        if (information.type === "error") {
                            toast.error(information.message, {toastId: 1})
                            rej(false)
                        } else {
                            try {
                                await getCleverCloudOrganisationApplicationInstances(schedule.organisationId, schedule.applicationId, async (app, rej) => {
                                    if (app.success) {
                                        if (app.application.length > 0) {
                                            await firebase.firestore().collection("logs").add({
                                                ...schedule,
                                                createdAt: new Date().toLocaleString(),
                                                executor: 'user',
                                            })
                                            res(true)
                                        } else {
                                            await startOrganisationApplication(schedule, result => {
                                                if (result) {
                                                    res(result)
                                                } else {
                                                    rej(result)
                                                }
                                            })
                                        }
                                    }else{
                                        toast.error("Unable to restart application", {toastId: 1})
                                    }
                                })
                            } catch (err) {
                                toast.error(err.message, {toastId: 1})
                                rej(false)
                            }
                        } }
                    }))
                })
                await Promise.all(promises)
                callback(true)
            }
        )
    }

    const startOrganisationApplication = async (schedule=ScheduleModel, callback) => {
        const url = "https://api.clever-cloud.com/v2/organisations/" + schedule.organisationId + "/applications/" + schedule.applicationId + "/instances"
        const method = "POST"

        getKeys(async ({key, secret}) => {
            const reponse = await fetch(url, {
                method: method,
                headers: requestHeaders(url, {
                        key: key,
                        secret: secret
                    }, method),             
                })

            const information = reponse.json();
            
            if (information.type === "error") {
                toast.error(information.message, {toastId: 1})
                callback(false)
            } else {
                try {
                    await firebase.firestore().collection("logs").add({
                        ...schedule,
                        createdAt: new Date().toLocaleString(),
                        executor: 'user',
                    })
                    callback(true)
                } catch (err) {
                    toast.error(err.message, {toastId: 1})
                    callback(false)
                }
            }
        })
    }

    const stopOrganisationApplication = async (schedule=ScheduleModel, callback) => {
        const url = "https://api.clever-cloud.com/v2/organisations/" + schedule.organisationId + "/applications/" + schedule.applicationId + "/instances"
        const method = "DELETE"
        getKeys(async ({key, secret}) => {
            const reponse = await fetch(url, {
                method: method,
                headers: requestHeaders(url, {
                        key: key,
                        secret: secret
                    }, method),             
                })

            const information = reponse.json();
            
            if (information.type === "error") {
                toast.error(information.message, {toastId: 1})
                callback(false)
            } else {
                try {
                    await firebase.firestore().collection("logs").add({
                        ...schedule,
                        createdAt: new Date().toLocaleString(),
                        executor: 'user',
                    })
                    callback(true)
                } catch (err) {
                    toast.error(err.message, {toastId: 1})
                    callback(false)
                }
            }
        })
    }


    return {
        getCleverCloudRequestToken,
        getCleverCloudAccessToken,
        getCleverCloudUser,
        getCleverCloudOrganisation,
        getCleverCloudOrganisationApplications,
        setOrganisationApplicationConfig,
        startOrganisationApplication,
        stopOrganisationApplication
    }
}