import React from 'react';

import MaterialDatatable from "material-datatable";
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingBottom: theme.spacing(12)
    }
}));

export default function Errors({data=[]}) {
    const classes = useStyles()
    
    const columns = [
        {   
            name: 'Application/Method',
            field: 'applicationName',
        },
        {
            name: 'Error',
            field: 'error',
        },
        {
            name: 'Organisation', 
            field: 'organisationName',
        },
        {
            name: 'Created At', 
            field: 'createdAt',
            options: {
                filter: false,
            }
        },
    ];
    
    const options = {
        filterType: 'multiselect',
        print: false,
        downloadOptions: {
            filename: 'error_Log_List.csv'
        },
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 15, 50],
        selectableRows: false
    };

    return(
        <div className={classes.container}>
        <MaterialDatatable
            title={"Errors"}
            data={data}
            columns={columns}
            options={options}
        />
        </div>
    )
}

 
