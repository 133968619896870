/* eslint-disable no-use-before-define */

import React, { useEffect, useState } from 'react';

import { ApplicationModel } from '../models/object.models';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Checkbox from '@material-ui/core/Checkbox';
import { ScheduleContext } from '../hooks/useContextProviders';
import TextField from '@material-ui/core/TextField';
import { useContext } from 'react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultiSelectApplications({selectedApplications}) {
    const applicationState = useContext(ScheduleContext).$context.applications
    const organisationState = useContext(ScheduleContext).$context.organisations

    const [applicationOptions, setApplicationOptions] = useState([ApplicationModel])

    useEffect(() => {

        let tempApplicationOptions = []
        applicationState.forEach( app => {
            let tempApp = app
            organisationState.forEach( org => {
                if (tempApp.organisationId === org.organisationId){
                    tempApp.organisationName = org.organisationName
                    tempApplicationOptions.push(tempApp)
                }
            })
        })
        setApplicationOptions(tempApplicationOptions)

    }, [applicationState.length, organisationState.length])

    return (
        <Autocomplete
        multiple
        id="checkboxes-tags"
        groupBy={(option) => option.organisationName}
        options={applicationOptions}
        onChange={(e, b) => selectedApplications(b)}
        getOptionLabel={(option) => option.applicationName}
        renderOption={(option, { selected }) => (
            <React.Fragment>
            <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
            />
            {option.applicationName}
            </React.Fragment>
        )}
        style={{ width: 500 }}
        renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Applications" placeholder="Please select your option(s)" />
        )}
    />
  );
}


