import { atOptions, everyOptions } from "../configs/schedule.config"

import flavors from "../configs/flavors.config"

const ApplicationModel = {
    applicationId: '',
    applicationName: '',
    organisationId: '',
    organisationName: ''
}

const OrganisationModel = {
    organisationId: '',
    organisationName: ''
}

const ScheduleModel = {
    applicationId: '',
    organisationId: '',
    applicationName: '',
    stopApplication: false,
    startApplication: false,
    minInstances: 1,
    maxInstances: 2,
    minFlavour: flavors[0].name,
    maxFlavour: flavors[2].name,
    scheduleEvery: everyOptions[0],
    scheduleAt: atOptions[0],
    uid: false
}

const LogModel = {
    applicationId: '',
    organisationId: '',
    applicationName: '',
    stopApplication: false,
    startApplication: false,
    minInstances: 1,
    maxInstances: 2,
    minFlavour: flavors[0].name,
    maxFlavour: flavors[2].name,
    scheduleEvery: everyOptions[0],
    scheduleAt: atOptions[0],
    uid: false,
    executor: ''
}

export { ApplicationModel, OrganisationModel, ScheduleModel, LogModel }