import { Paper, Tab, Tabs, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import Errors from "../views/errors";
import Logs from '../views/logs';
import Main from '../views/main';
import { ScheduleContext } from '../hooks/useContextProviders';
import Schedules from '../views/schedules';
import Settings from '../views/settings';
import { ToastContainer } from 'react-toastify';
import { useContext } from 'react';
import useDatabase from '../hooks/useDatabase';

const useStyles = makeStyles((theme) => ({
  container: {
        margin: theme.spacing(6)
  },
}));


export default function Home() {
    const classes = useStyles()
    const [activeTab, setActiveTab] = useState(0)
    const { $updateContext } = useContext(ScheduleContext)

    const ContentSwitcher = () => {
        switch(activeTab){
            case 0:
                return <Schedules data={_db.schedules}/>
            case 1:
                return <Logs data={_db.logs}/>
            case 2:
                return <><Settings/></>
            case 3:
                return <Errors data={_db.errors}/>
            default:
                return <><Schedules data={_db.schedules}/></>
        }
    }

    const _db = useDatabase()

    useEffect(() => {
        localStorage.removeItem('temp_secret')
        _db.getUser((data, user) => {
            $updateContext({applications: data.applications, organisations: data.organisations, userName: data.cleverCloudName, userEmail: user.email})
        });
        _db.startLogListener()
        _db.startScheduleListener()
        _db.startErrorListener()
    }, [])

    return(
        <Main>
            <ToastContainer/>
                <Paper square>
                    <Tabs
                        value={activeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(_, value) => setActiveTab(value)}
                        aria-label="main-tabs"
                    >
                        <Tab  label="Schedules" />
                        <Tab label="Logs" />
                        <Tab label="Settings"/>
                        <Tab label="Errors"/>
                    </Tabs>
                </Paper>

                <div className={classes.container}>
                    <ContentSwitcher/>
                </div>
        </Main>
    )
}