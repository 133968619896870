const everyOptions = [
    "Monday", "Tuesday", "Wednesday","Thursday", "Friday", "Business Day", "Holiday" , "Weekend" 
]

const atOptions = [
    "1 AM",
    "2 AM",
    "3 AM",
    "4 AM",
    "5 AM",
    "6 AM",
    "7 AM",
    "8 AM",
    "9 AM",
    "10 AM",
    "11 AM",
    "12 PM",
    "13 PM",
    "14 PM",
    "15 PM",
    "16 PM",
    "17 PM",
    "18 PM",
    "19 PM",
    "20 PM",
    "21 PM",
    "22 PM",
    "23 PM",
]

export {everyOptions, atOptions}