import 'firebase/firestore';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React, { useState } from 'react';
import { ScheduleContext, ThemeContext } from './hooks/useContextProviders';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Home from './pages/home.page';
import Login from './pages/login.page';
import ReactDOM from 'react-dom';
import { ScheduleContextModel } from './models/context.models';
import firebase from 'firebase/app'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_EDDY_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_EDDY_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_EDDY_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_EDDY_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_EDDY_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_EDDY_MESSANGING_SENDER_ID,
  appId:process.env.REACT_APP_FIREBASE_EDDY_APP_ID
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const App = () => {
  const [context, setContext] = useState(ScheduleContextModel)
  const [darkState, setDarkState] = useState(localStorage.getItem("dark_theme") ? localStorage.getItem("dark_theme") === 'true' : true)
  const palletType = darkState ? "dark" : "light";
  const mainPrimaryColor = darkState ? '#ff9800' : '#1976d2';
  const mainSecondaryColor = darkState ? '#f57c00' : '#1976d2';
  const darklight = darkState ? '#424242': '#ffffff';
  const lightdark = darkState ? '#ffffff': '#424242';
  
  const darkTheme = createMuiTheme({
    palette: {
      type: palletType,
      primary: {
        main: mainPrimaryColor
      },
      secondary: {
        main: mainSecondaryColor
      },
      background: {
        default: darklight,
        paper: darklight
      },
      text: {
        primary: lightdark,
        secondary: lightdark
      }
    }
  });
  
  const handleThemeChange = () => {
    localStorage.setItem("dark_theme", !darkState )
    setDarkState(!darkState);
  };

  return(
    <ThemeContext.Provider value={{changeTheme: handleThemeChange, darkMode: darkState}}>
      <ThemeProvider theme={darkTheme}>
        <ScheduleContext.Provider value={{$context: context.$context, $updateContext: (v) => setContext( e => ({$context: {...e.$context, ...v}}))}}>
          <Switch>
            <Route exact path='/' component={Login}/>
            <Route exact path='/home' component={Home}/>
          </Switch>
        </ScheduleContext.Provider>
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

