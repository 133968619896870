import { AirbnbSlider, AirbnbThumbComponent } from './slider';
import { Button, Checkbox, CircularProgress, DialogTitle, FormControlLabel, IconButton, Switch, Tooltip, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FlashOnIcon from '@material-ui/icons/FlashOn'
import MultiSelectApplications from './multiselectApplications';
import flavors from '../configs/flavors.config';
import useSchedule from '../hooks/useSchedule';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: "100%",
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));


export default function ConfigureScheduleDialog({disabled}) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();


  const schedule = useSchedule()

  const handleClickOpen = () => {
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false)
    schedule.reset()
  };

  const updateApplications = (e) => {
      schedule.addApplication(e)
  }


  return (
    <div>
     <Tooltip title="Set Configuration for application(s) now">
        <IconButton disabled={disabled} className={classes.button} onClick={handleClickOpen} color="inherit">
          <FlashOnIcon />
        </IconButton>
      </Tooltip>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="max-width-dialog-title">Configure Application(s) now</DialogTitle>
            <DialogContent>
                {!schedule.schedule.uid && <MultiSelectApplications selectedApplications={e => updateApplications(e)}/>}
                <div style={{marginTop: 10}} />

                <div style={{paddingLeft: 40, paddingRight: 40}}>
                    <FormControlLabel control={ 
                        <Switch 
                        checked={schedule.schedule.stopApplication} 
                        onClick={() => {
                            schedule.updateSchedule({target: {name: 'startApplication', value: false}})
                            schedule.updateSchedule({target: {name: 'stopApplication', value: !schedule.schedule.stopApplication}})
                        }} 
                        color="secondary"
                    />}
                    label={"Stop Application(s)"}/>
                </div>
                {!schedule.schedule.stopApplication && <div style={{paddingLeft: 40, paddingRight: 40}}>
                    <Typography style={{display: "box", fontWeight: "bold"}}>
                        Set Application Instances
                    </Typography>

                    <Typography style={{display: "box"}}>
                        {`MinInstances: ${schedule.schedule.minInstances} ~ MaxInstances: ${schedule.schedule.maxInstances}`}
                    </Typography>

                    <div style={{marginTop: 10}} />

                    <AirbnbSlider 
                    value={[schedule.schedule.minInstances, schedule.schedule.maxInstances]}
                    onChange={(_, value) => {
                        schedule.updateSchedule({target: {name: 'minInstances', value: value[0]}})
                        schedule.updateSchedule({target: {name: 'maxInstances', value: value[1]}})
                    }}
                    ThumbComponent={AirbnbThumbComponent}
                    valueLabelDisplay="auto" step={1} marks min={1}
                    max={40} aria-labelledby="range-slider" />
                </div>}

                <div style={{marginTop: 10}} />

                {!schedule.schedule.stopApplication && <div style={{paddingLeft: 40, paddingRight: 40}}>
                    <Typography style={{display: "box", fontWeight: "bold"}}>
                        Set Application Flavors
                    </Typography>

                    <Typography style={{display: "box"}}>
                        {`MinFlavor: ${schedule.schedule.minFlavour} ~ MaxFlavor: ${schedule.schedule.maxFlavour}`}
                    </Typography>

                    <div style={{marginTop: 10}} />

                    <AirbnbSlider 
                    value={[flavors.findIndex(e => e.name === schedule.schedule.minFlavour), flavors.findIndex(e => e.name === schedule.schedule.maxFlavour)]}
                    onChange={(_, value) => {
                        schedule.updateSchedule({target: {name: 'minFlavour', value: flavors[value[0]].name}})
                        schedule.updateSchedule({target: {name: 'maxFlavour', value: flavors[value[1]].name}})
                    }}
                    ThumbComponent={AirbnbThumbComponent} 
                    step={1} marks min={0} max={flavors.length -1 }
                    aria-labelledby="range-slider" />
                </div>}

                <div style={{marginTop: 10}} />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => schedule.configure(false, () => {
                    handleClose()    
                })}>
                    {schedule.loading ? <CircularProgress color='inherit'/> : 'Submit'}
                </Button>
            </DialogActions>
        </Dialog>
    </div>
  );
}