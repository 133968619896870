
// cpu sizes = flavors
const flavors = [
        {
            "name":"pico",
            "mem":256,
            "cpus":1,
            "gpus":0,
            "disk":null,
            "price":0.1073883162,
            "available":true,
            "microservice":true,
            "nice":5,
            "selected":true,
            "disabled":false
        },
        {
            "name":"nano",
            "mem":512,
            "cpus":1,
            "gpus":0,
            "disk":null,
            "price":0.1431844215,
            "available":true,
            "microservice":true,
            "nice":5,
            "selected":false,
            "disabled":false
        },
        {
            "name":"XS",
            "mem":1024,
            "cpus":1,
            "gpus":0,
            "disk":null,
            "price":0.3436,
            "available":true,
            "microservice":false,
            "nice":0,
            "selected":false,
            "disabled":false
        },
        {
            "name":"S",
            "mem":2048,
            "cpus":2,
            "gpus":0,
            "disk":null,
            "price":0.6873,
            "available":true,
            "microservice":false,
            "nice":0,
            "selected":false,
            "disabled":false
        },
        {
            "name":"M",
            "mem":4096,
            "cpus":4,
            "gpus":0,
            "disk":null,
            "price":1.7182,
            "available":true,
            "microservice":false,
            "nice":0,
            "selected":false,
            "disabled":false
        },
        {
            "name":"L",
            "mem":8192,
            "cpus":6,
            "gpus":0,
            "disk":null,
            "price":3.4364,
            "available":true,
            "microservice":false,
            "nice":0,
            "selected":false,
            "disabled":false
        },
        {
            "name":"XL",
            "mem":16384,
            "cpus":8,
            "gpus":0,
            "disk":null,
            "price":6.8729,
            "available":true,
            "microservice":false,
            "nice":0,
            "selected":false,
            "disabled":false
        },
        {
            "name":"2XL",
            "mem":24576,
            "cpus":12,
            "gpus":0,
            "disk":null,
            "price":13.7458,
            "available":true,
            "microservice":false,
            "nice":0,
            "selected":false,
            "disabled":false
        },
        {
            "name":"3XL",
            "mem":32768,
            "cpus":16,
            "gpus":0,
            "disk":null,
            "price":27.4915,
            "available":true,
            "microservice":false,
            "nice":0,
            "selected":false,
            "disabled":false
        }
    ]
    
    
    export default flavors
