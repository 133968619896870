import 'firebase/auth';
import 'firebase/firestore';

import firebase from 'firebase/app';
import {
    toast
} from 'react-toastify';
import useCleverCloud from './useCleverCloud';
import {
    useHistory
} from 'react-router';
import {
    useState
} from "react";
import validator from "email-validator";

export default function useLogin() {

    const cleverCloud = useCleverCloud()
    const history = useHistory()

    const [cleverCloudRequestToken, setCleverCloudRequestToken] = useState(false)
    const [forgotPassword, setForgotPassword] = useState(false)
    const [loading, isLoading] = useState(false)
    const [user, setUser] = useState({
        email: '',
        password: ''
    })

    const updateUser = (e) => {
        const {
            name,
            value
        } = e.target;
        setUser({
            ...user,
            [name]: value
        })
    }

    const getCleverCloudAccessToken = () => firebase.auth().onAuthStateChanged(fbUser => {
        if (fbUser) {
            isLoading(true)
            cleverCloud.getCleverCloudAccessToken((res, rej) => {
                if (res.success) {
                    firebase.firestore().collection('users').doc(fbUser.uid).set({
                        uid: fbUser.uid,
                        token: res.token.token,
                        secret: res.token.secret
                    }).then(() => {
                        syncUser(success => {
                            if (success) {
                                toast.success('access granted')
                                window.location.replace('/home')
                            } else {
                                toast.error('Access token error', {
                                    toastId: 1
                                })
                                isLoading(false)
                            }
                        })
                    }).catch(err => {
                        console.log('error in access token', err);
                        toast.error(err.message, {
                            toastId: 1
                        })
                        isLoading(false)
                    })
                } else {
                    console.log('error in access token');
                    toast.error(rej, {
                        toastId: 1
                    })
                    isLoading(false)
                }
            });
        }
    })

    const syncUser = (callback) => firebase.auth().onAuthStateChanged(fbUser => {
        if (fbUser) {
            cleverCloud.getCleverCloudUser((res1, rej1) => {
                if (res1.success) {
                    cleverCloud.getCleverCloudOrganisation(res1.user.cleverCloudID, (res2, rej2) => {
                        if (res2.success) {
                            if (res2.organisations.length > 0) {
                                cleverCloud.getCleverCloudOrganisationApplications(res2.organisations, (res3, rej3) => {
                                    if (res2.success) {
                                        firebase.firestore().collection('users').doc(fbUser.uid).update({
                                            cleverCloudName: res1.user.cleverCloudName,
                                            cleverCloudId: res1.user.cleverCloudID,
                                            organisations: res2.organisations,
                                            applications: res3.applications
                                        }).then(() => {
                                            callback(true)
                                        }).catch(err => {
                                            callback(false)
                                            toast.error(err.message, {
                                                toastId: 1
                                            })
                                        })
                                    } else {
                                        callback(false)
                                        toast.error(rej3, {
                                            toastId: 1
                                        })
                                    }
                                })
                            } else {
                                callback(false)
                                toast.warning('No applications linked to ' + process.env.REACT_APP_CC_ORGANISATION, {
                                    toastId: 1
                                })
                            }
                        } else {
                            callback(false)
                            toast.error(rej2, {
                                toastId: 1
                            })
                        }
                    })
                } else {
                    callback(false)
                    toast.error(rej1, {
                        toastId: 1
                    })
                }
            })
        } else {
            toast.warning('You are not logged in', {
                toastId: 1
            })
            window.location.replace('/')
        }
    })


    const logIn = async (e) => {
        e.preventDefault()
        if (!validator.validate(user.email)) {
            return toast.error('Please enter a valid email address', {
                toastId: 1
            })
        } else if (!user.password.trim()) {
            return toast.error('Please enter your password', {
                toastId: 1
            })
        } else {
            isLoading(true)
            try {
                cleverCloud.getCleverCloudRequestToken(async (res, rej) => {
                    if (res.success) {
                        const loggedInUser = await firebase.auth().signInWithEmailAndPassword(user.email, user.password);
                        const accessTokens = await firebase.firestore().collection('users').doc(loggedInUser.user.uid).get();
                        if (accessTokens.exists && accessTokens.data().token && accessTokens.data().secret) {
                            isLoading(false)
                            return history.push('/home');
                        } else {
                            return document.location.replace("https://api.clever-cloud.com:443/v2/oauth/authorize?oauth_token=" + res.token)
                        }
                    } else {
                        toast.error(rej, {
                            toastId: 1
                        })
                    }
                })

            } catch (err) {
                isLoading(false)
                toast.error('Incorrect email or password', {
                    toastId: 1
                });
            }
        }
    }

    const resetPassword = (e) => {
        e.preventDefault()
        if (!validator.validate(user.email)) {
            return toast.error('Please enter a valid email address', {
                toastId: 1
            })
        }
        isLoading(true)
        firebase.auth().sendPasswordResetEmail(user.email).then(() => {
            isLoading(false)
            return toast.success('Password reset email sent', {
                toastId: 1
            })
        }).catch(err => {
            isLoading(false)
            toast.error(err.message, {
                toastId: 1
            });
        })
    }

    return {
        cleverCloudRequestToken,
        setCleverCloudRequestToken,
        getCleverCloudAccessToken,
        logIn,
        forgotPassword,
        setForgotPassword,
        resetPassword,
        updateUser,
        user,
        loading,
        syncUser
    }
}