import { AppBar, Toolbar, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';

import { ScheduleContext } from '../hooks/useContextProviders';

const useStyles = makeStyles(() => ({
  footer: { 
        position: "fixed",
        bottom: 15,
        left: 5,
        right: 5,
        backgroundColor: 'white',
        height: 40,
    },
}));

export default function Footer() {
    const classes = useStyles()

    const { $context } = useContext(ScheduleContext)

    return(
        <div className={classes.footer}>
            <AppBar style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, width: 'auto'}} position="static">
                <Toolbar variant='dense'>
                    <p style={{textAlign: 'center', color: 'white', width: '100%'}}>Welcome {$context.userName}, you are signed in as {$context.userEmail}: {process.env.NODE_ENV.toLocaleUpperCase()}</p>
                </Toolbar>
            </AppBar>
        </div>

    )
}