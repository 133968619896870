import 'firebase/firestore';

import { ScheduleModel } from "../models/object.models";
import firebase from 'firebase/app';
import { toast } from 'react-toastify';
import useCleverCloud from './useCleverCloud';
import { useState } from "react";

export default function useSchedule() {

    const [schedule, setSchedule] = useState(ScheduleModel)
    const [loading, isLoading] = useState(false)
    const cleverloud = useCleverCloud()

    const [applications, setApplications] = useState([])

    const updateSchedule = (e) => {
        const { name, value } = e.target
        setSchedule(p => ({...p, [name]: value}))
    }

    const reset = () => {
        setSchedule(ScheduleModel)
        setApplications([])
    }

    const setupSchedule = (item) => {
        setSchedule(item)
        setApplications([{applicationName: item.applicationName, applicationId: item.applicationId, organisationId: item.organisationId}])
    }

    const addApplication = (e) => {
        setApplications(e)
    }

    const adapterForSchedules = (uid) => {
        let temp = []
        applications.forEach( app => {
            temp.push({
                ...schedule,
                ...app,
                createdAt: new Date().toLocaleString(),
                owner: uid
            })
        })
        return temp;
    }

    const submit = (callback) => firebase.auth().onAuthStateChanged(async u => {
        if (u){
            if (!schedule.stopApplication){
                if (!schedule.minFlavour || !schedule.maxFlavour){
                    return toast.warn('Please select your flavour', {toastId: 1})
                }
                if (!schedule.minInstances || !schedule.maxFlavour){
                    return toast.warn('Please select your intances', {toastId: 1})
                }
            }
            if (!schedule.scheduleEvery || !schedule.scheduleAt){
                return toast.warn('Please select your schedule', {toastId: 1})
            }
            if (applications.length < 1) {
                return toast.warn('Please select applications', {toastId: 1})
            }
            isLoading(true)
            let promises = []
            adapterForSchedules(u.uid).forEach( s => {
                promises.push(new Promise((res, rej) => {
                    if (s.uid){
                        firebase.firestore().collection('schedules').doc(s.uid).update(s)
                        .then(() => res(true)).catch(err => rej(err))
                    }else{
                        firebase.firestore().collection('schedules').add(s)
                        .then(() => res(true)).catch(err => rej(err))
                    }
                }))
            })
            await Promise.all(promises)
            isLoading(false)
            return callback(true)
        }}
    )

    const deleteSchedules = (rows, data=[]) => {
        let promises = []
        rows.data.forEach( s => {
            promises.push(new Promise((res, rej) => {
                firebase.firestore().collection('schedules').doc(data[s.dataIndex].uid).delete()
                .then(() => res(true)).catch(err => rej(err))
            }))
        })
        return Promise.all(promises)
    }

    const configure = (s, callback) => firebase.auth().onAuthStateChanged(async u => {
        if (u){
            if (!s){
                if (!schedule.stopApplication){
                    if (!schedule.minFlavour || !schedule.maxFlavour){
                        return toast.warn('Please select your flavour', {toastId: 1})
                    }
                    if (!schedule.minInstances || !schedule.maxFlavour){
                        return toast.warn('Please select your intances', {toastId: 1})
                    }
                }
                if (applications.length < 1) {
                    return toast.warn('Please select applications', {toastId: 1})
                }
                isLoading(true)
                cleverloud.setOrganisationApplicationConfig(adapterForSchedules(u.uid), res => {
                    if (res) {
                        toast.success('Configurations set!', {toastId: 1})
                    }
                    isLoading(false)
                    return callback(true)
                })
            } else {
                cleverloud.setOrganisationApplicationConfig([s], res => {
                    if (res) {
                        toast.success('Configurations set!', {toastId: 1})
                    }
                    isLoading(false)
                    return callback(true)
                })
            }
        }}
    )

    return{
        schedule,
        updateSchedule,
        setupSchedule,
        addApplication,
        submit,
        reset,
        loading,
        deleteSchedules,
        configure
    }
}