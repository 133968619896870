import { Button, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react';

import {ScheduleContext, ThemeContext} from '../hooks/useContextProviders';
import { toast } from 'react-toastify';
import useLogin from '../hooks/useLogin';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    item: {
        fontSize: '1.2rem',
        color: theme.palette.text.primary,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)

    },
    header: {
        color: theme.palette.text.primary,
    }
}));

export default function Settings() {
    const classes = useStyles()
    const changeTheme = useContext(ThemeContext).changeTheme
    const [refreshLoader, setRefreshLoader] = useState(false)
    const [runScheduleLoader, setRunScheduleLoader] = useState(false)
    const login = useLogin()

    const refresh = () => {
        setRefreshLoader(true)
        login.syncUser(success => {
            if (success){
                toast.success("Applications refreshed", {toastId: 1})
            }else{
                toast.error("Refresh Failed", {toastId: 1})
            }
            setRefreshLoader(false)
        })
    }

    const runSchedules = () => firebase.auth().onAuthStateChanged(async user => {
        if (user){
            setRunScheduleLoader(true)
            firebase.firestore().collection("users").doc(user.uid).get().then(async u => {
                axios.post("/runSchedules",{}, {
                    params: {
                        data: u.data(),
                        token: await user.getIdToken()
                    }
                }).then(({data}) => {
                    if(data.success){
                        toast.success("Schedules were run", {toastId: 1})
                    }else{
                        toast.error(data.message, {toastId: 3})
                    }
                    setRunScheduleLoader(false)
                }).catch(err => {
                    toast.error(err.message, {toastId: 2})
                    setRunScheduleLoader(false)
                    console.warn(err)
                })
            })
        }
    })

    return(
        <>
        <Typography variant="h4" className={classes.header}>
            Modify Application
        </Typography>

        <div>
            <Typography className={classes.item}>
                1. Resync all applications and organisations ( Will not lose schedules )
            </Typography>
            <Button variant='outlined' color='primary' onClick={refresh}>{refreshLoader ? <CircularProgress/> : "Resync"}</Button>
        </div>
        <div>
            <Typography className={classes.item}>
                2. Change Theme (Beta)
            </Typography>
            <Button variant='outlined' color='primary' onClick={changeTheme}> Change </Button>
        </div>
        <div>
            <Typography className={classes.item}>
                2. Run Schedules Now (Beta)
            </Typography>
            <Button variant='outlined' color='primary' onClick={runSchedules}> {runScheduleLoader ? <CircularProgress/> : "Run Now"} </Button>
        </div>
        </>
    )
}