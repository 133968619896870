import { CircularProgress, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';

import MaterialDatatable from "material-datatable";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import ScheduleDialog from '../components/scheduleDialog';
import useSchedule from '../hooks/useSchedule';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingBottom: theme.spacing(12)
    }
}));

export default function Schedules({data=[]}) {
    const classes = useStyles()
    const schedule = useSchedule()
    const [nestedLoaders, setNestedLoaders] = useState({})
    
    const columns = [
        {   
            name: 'Application', 
            field: 'applicationName',
        },
        {
            name: 'Run every:', 
            field: 'scheduleEvery'
        },
        {
            name: 'Time (hour)', 
            field: 'scheduleAt',
        },
        {
            name: '(-) Flavours', 
            field: 'minFlavour',
            options: {
                customValue: (v) => v.minFlavour,
                customSortValue: (v) => v.minFlavour,
                customBodyRender: (value) => {
                    return(
                        <div>
                            {value.stopApplication === true ? 'n/a' : value.minFlavour }
                        </div>
                    )
                }
            }        
        },
        {
            name: '(+) Flavours', 
            field: 'maxFlavour',
            options: {
                customValue: (v) => v.maxFlavour,
                customSortValue: (v) => v.maxFlavour,
                customBodyRender: (value) => {
                    return(
                        <div>
                            {value.stopApplication === true ? 'n/a' : value.maxFlavour }
                        </div>
                    )
                }
            }        
        },
        {
            name: '(-) Instances', 
            field: 'minInstances',
            options: {
                customValue: (v) => v.minInstances,
                customSortValue: (v) => v.minInstances,
                customBodyRender: (value) => {
                    return(
                        <div>
                            {value.stopApplication === true ? 'n/a' : value.minInstances }
                        </div>
                    )
                }
            }        
        },
        {
            name: '(+) Instances', 
            field: 'maxInstances',
            options: {
                customValue: (v) => v.maxInstances,
                customSortValue: (v) => v.maxInstances,
                customBodyRender: (value) => {
                    return(
                        <div>
                            {value.stopApplication === true ? 'n/a' : value.maxInstances}
                        </div>
                    )
                }
            }        
        },
        {
            name: 'Stop Application', 
            field: 'stopApplication',
            options: {
                customBodyRender: (value) => {
                    return(
                        <div>
                            {value.stopApplication === true ? 'True' : 'False'}
                        </div>
                    )
                }
            }
        },
        {
            name: 'Organisation', 
            field: 'organisationName',
            options: {
                display: false,
            }
        },
        {
            name: 'Created At', 
            field: 'createdAt',
            options: {
                display: false,
                filter: false,
            }
        },
        {
            name: 'Actions', 
            field: 'actions',
            options: {
                download: false,
                filter: false,
                customBodyRender: (value) => {
                    return(
                        <div style={{display: 'flex'}}>
                            <ScheduleDialog edit={value}/>
                            <div style={{width: 10}}/>
                            <Tooltip title="Run Configuration">
                                <IconButton style={{padding: 0}} onClick={() => {
                                    setNestedLoaders(e => ({...e, [value.uid]: true}))
                                    schedule.configure(value, () => setNestedLoaders(e => ({...e, [value.uid]: false})))
                                    }}>
                                    {nestedLoaders[value.uid] !== undefined ? 
                                    nestedLoaders[value.uid] === true ? 
                                    <CircularProgress size={16}/> : <PlayCircleOutlineIcon/> : <PlayCircleOutlineIcon/> }
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                }
            }
        },
    ];
    
    const options = {
        filterType: 'multiselect',
        print: false,
        download: false,
        downloadOptions: {
            filename: 'Schedule_List.csv'
        },
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 15, 50],
        onRowsDelete: rows => schedule.deleteSchedules(rows, data)
    };

    return(
        <div className={classes.container}>
        <MaterialDatatable
            title={"Schedules"}
            data={data}
            columns={columns}
            options={options}
        />
        </div>
    )
}

 
