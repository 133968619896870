import 'firebase/auth';

import { CircularProgress, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';

import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import AppBar from '@material-ui/core/AppBar';
import ConfigureScheduleDialog from './configureScheduleDialog';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import { ScheduleContext } from '../hooks/useContextProviders';
import ScheduleDialog from './scheduleDialog';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import firebase from 'firebase/app';
import { makeStyles } from '@material-ui/core/styles';
import { useContext } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

export default function Appbar() {
  const classes = useStyles();
  const applicationState = useContext(ScheduleContext).$context.applications
  const organisationState = useContext(ScheduleContext).$context.organisations


  const [loading, isLoading] = useState(false)

  const signOut = () => {
    isLoading(true)
    return firebase.auth().signOut().then(() => {
      window.location.replace('/')
    }).finally(() => {
      isLoading(false)
    })
  }

  return (
    <div className={classes.root}>
      <AppBar style={{borderTopLeftRadius: 10, borderTopRightRadius: 10}} position="static">
        <Toolbar variant='dense'>
          <IconButton edge="start" className={classes.button} color="inherit">
            <AccessAlarmsIcon />
          </IconButton>
          <Typography style={{display: 'flex', flex: 1}} variant="h6" color="inherit">
            Eddy2.0
          </Typography>
          <ConfigureScheduleDialog disabled={!applicationState || !organisationState}/>
          <ScheduleDialog disabled={!applicationState || !organisationState}/>
          <Tooltip title="Sign Out">
            <IconButton edge="end" onClick={signOut} className={classes.button} color="inherit">
              {loading ? <CircularProgress color='inherit'/> : <ExitToAppIcon /> }
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </div>
  );
}