import { ApplicationModel, OrganisationModel, ScheduleModel } from "./object.models"

const ScheduleContextModel = {
    $context: {
        schedule: ScheduleModel,
        applications: [ApplicationModel],
        organisations: [OrganisationModel],
        openDialog: false,
        userName: '',
        userEmail: '',
    },
    $updateContext: () => {}
}

const ThemeContextModel = {
    changeTheme: () => {}, 
    darkMode: true
}

export { ScheduleContextModel, ThemeContextModel }