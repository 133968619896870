import React from 'react';

import MaterialDatatable from "material-datatable";
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingBottom: theme.spacing(12)
    }
}));

export default function Logs({data=[]}) {
    const classes = useStyles()
    
    const columns = [
        {   
            name: 'Application', 
            field: 'applicationName',
        },
        {
            name: 'Run every:', 
            field: 'scheduleEvery',
            options: {
                display: false,
            }
        },
        {
            name: 'Time (hour)', 
            field: 'scheduleAt',
            options: {
                display: false,
            }
        },
        {
            name: '(-) Flavours', 
            field: 'minFlavour',
            options: {
                customValue: (v) => v.minFlavour,
                customSortValue: (v) => v.minFlavour,
                customBodyRender: (value) => {
                    return(
                        <div>
                            {value.stopApplication === true ? 'n/a' : value.minFlavour }
                        </div>
                    )
                }
            }        
        },
        {
            name: '(+) Flavours', 
            field: 'maxFlavour',
            options: {
                customValue: (v) => v.maxFlavour,
                customSortValue: (v) => v.maxFlavour,
                customBodyRender: (value) => {
                    return(
                        <div>
                            {value.stopApplication === true ? 'n/a' : value.maxFlavour }
                        </div>
                    )
                }
            }        
        },
        {
            name: '(-) Instances', 
            field: 'minInstances',
            options: {
                customValue: (v) => v.minInstances,
                customSortValue: (v) => v.minInstances,
                customBodyRender: (value) => {
                    return(
                        <div>
                            {value.stopApplication === true ? 'n/a' : value.minInstances }
                        </div>
                    )
                }
            }        
        },
        {
            name: '(+) Instances', 
            field: 'maxInstances',
            options: {
                customValue: (v) => v.maxInstances,
                customSortValue: (v) => v.maxInstances,
                customBodyRender: (value) => {
                    return(
                        <div>
                            {value.stopApplication === true ? 'n/a' : value.maxInstances}
                        </div>
                    )
                }
            }        
        },
        {
            name: 'Stop Application', 
            field: 'stopApplication',
            options: {
                customValue: (v) => v.stopApplication === true ? 'True' : 'False',
                customSortValue: (v) => v.stopApplication === true ? 'True' : 'False',
                customBodyRender: (value) => {
                    return(
                        <div>
                            {value.stopApplication === true ? 'True' : 'False'}
                        </div>
                    )
                }
            }
        },
        {
            name: 'Organisation', 
            field: 'organisationName',
            options: {
                display: false,
            }
        },
        {
            name: 'Created At', 
            field: 'createdAt',
            options: {
                filter: false,
            }
        },
        {
            name: 'Executor', 
            field: 'executor'
        },
    ];
    
    const options = {
        filterType: 'multiselect',
        print: false,
        download: false,
        downloadOptions: {
            filename: 'Schedule_Log_List.csv'
        },
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 15, 50],
        selectableRows: false
    };

    return(
        <div className={classes.container}>
        <MaterialDatatable
            title={"Logs"}
            data={data}
            columns={columns}
            options={options}
        />
        </div>
    )
}

 
